.container-banner {
  background-image: url("/src/assets/imgs/fondoBanner.jpg");
  background-position: 0% 15%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 720px;
}

@media screen and (max-width: 768px) {
  .container-banner {
    height: 700px;
    background-position: 50% 100%;
  }
}
